import axios from '../axios';

export const all = (params = {}) => {
  return axios.get('/downloadable-materials/files-to-download', { params });
};

export const allByLanguage = (params = {}) => {
  return axios.get('/downloadable-materials/files-to-download/by-language', { params });
};

export const show = (id, params = {}) => {
  return axios.get(`/downloadable-materials/files-to-download/${id}`, { params });
};

export const create = (payload) => {
  return axios.post('/downloadable-materials/files-to-download', payload);
};

export const update = (id, payload) => {
  return axios.post(`/downloadable-materials/files-to-download/${id}`, payload);
};

export const destroy = (id) => {
  return axios.delete(`/downloadable-materials/files-to-download/${id}`);
};
