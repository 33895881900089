import axios from '@/http/axios';

export const all = () => {
  return axios.get('/posts');
};

export const show = (slug) => {
  return axios.get(`/posts/${slug}`);
};

export const create = (payload) => {
  return axios.post('/posts', payload);
};

export const update = (slug, payload) => {
  return axios.post(`/posts/${slug}/update`, payload);
};

export const archive = (slug) => {
  return axios.delete(`/posts/${slug}/archive`);
};
