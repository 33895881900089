export const createFormFromObject = (obj) => {
  const formData = new FormData();
  Object.keys(obj).forEach((key) => {
    // if array stay with array
    if (Array.isArray(obj[key])) {
      formData.append(key, JSON.stringify(obj[key]));
    } else {
      formData.append(key, obj[key]);
    }
  });

  return formData;
};

export const createBlogPostForm = (form) => {
  const formData = new FormData();

  formData.append('lang', form.lang);
  formData.append('title', form.title);
  formData.append('title_image', form.title_image.file, form.title_image.file.fileName);
  formData.append('description_1', form.description_1);
  formData.append('description_2', form.description_2);
  formData.append('description_3', form.description_3 ?? '');
  formData.append('seo_description', form.seo_description);
  formData.append('slug', form.slug);
  formData.append('is_active', form.is_active ? 1 : 0);

  if (form.quote) {
    formData.append('quote', !form.quote ? '' : form.quote);
    formData.append('quote_author', form.quote_author);
    formData.append('quote_author_desc', form.quote_author_desc);
    formData.append('quote_author_image', form.quote_author_image.file, form.quote_author_image.file.fileName);
  }

  for (let i = 0; i < form.site_images.length; i++) {
    formData.append(`site_images[${i}]`, form.site_images[i].file, form.site_images[i].file.fileName);
  }

  for (let i = 0; i < form.categories.length; i++) {
    formData.append(`categories[${i}]`, form.categories[i].id);
  }

  if (form.attachments.length > 0) {
    for (let i = 0; i < form.attachments.length; i++) {
      formData.append(`attachments[${i}]`, form.attachments[i].id);
    }
  }

  if (form.parent) {
    formData.append('parent_id', form.parent.id);
  } else {
    formData.append('parent_id', '');
  }

  if (form.videos.length > 0) {
    for (let i = 0; i < form.videos.length; i++) {
      formData.append(`videos[${i}]`, form.videos[i]);
    }
  }

  return formData;
};

export const createBasicPageForm = (data) => {
  const form = new FormData();

  form.append('lang', data.lang);
  form.append('title', data.title);
  form.append('value', data.value);
  form.append('title_image', data.title_image.file, data.title_image.file.fileName);

  return form;
};

export const createInspirationForm = (data) => {
  const form = new FormData();
  form.append('lang', data.lang);
  form.append('name', data.name);
  form.append('short_description', data.short_description);
  form.append('description', data.description);
  form.append('slug', data.slug);
  form.append('image', data.image.file, data.image.file.fileName);
  form.append('seo_description', data.seo_description);

  if (data.tags.length > 0) {
    for (let i = 0; i < data.tags.length; i++) {
      form.append(`tags[${i}]`, data.tags[i]);
    }
  }

  for (let i = 0; i < data.products.length; i++) {
    const sku =
      typeof data.products[i].product_symbol === 'string'
        ? data.products[i].product_symbol
        : data.products[i].product_symbol.sku;

    form.append(`products[${i}][product_symbol]`, sku);
    form.append(`products[${i}][x]`, data.products[i].x);
    form.append(`products[${i}][y]`, data.products[i].y);
  }

  if (data.posts.length > 0) {
    for (let i = 0; i < data.posts.length; i++) {
      form.append(`posts[${i}]`, data.posts[i].id);
    }
  }

  return form;
};

export const createInspirationsSiteForm = (data) => {
  const form = new FormData();

  form.append('lang', data.lang);
  form.append('title', data.title);
  form.append('description', data.description);
  form.append('text', data.text);
  form.append('seo_description', data.seo_description);
  form.append('title_image', data.title_image.file, data.title_image.file.fileName);

  return form;
};
