<template>
  <validation-observer ref="postObserver">
    <b-form @submit.prevent="validationForm" class="form-container">
      <b-col>
        <h4 class="mb-2">Konfiguracja postu</h4>

        <b-form-group class="mt-2" label="Oryginalny post (nowy post będzie traktowany jako tłumaczenie)">
          <v-select
            id="v-origin-select"
            label="title"
            placeholder="Wybierz oryginalny post"
            v-model="form.parent"
            :clearable="true"
            :deselectFromDropdown="false"
            :options="availableParents"
            :disabled="form.is_parent"
            @input="resetLanguage" />

          <b-button
            v-show="form.parent"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="cloneMedia">
            <span>Sklonuj media dla tego postu</span>
          </b-button>
        </b-form-group>

        <b-form-group class="mt-2" label="Język">
          <validation-provider #default="{ errors }" name="Język" rules="required">
            <v-select
              id="v-language-select"
              label="value"
              placeholder="Wybierz język"
              v-model="lang"
              :clearable="false"
              :deselectFromDropdown="false"
              :options="availableLanguages"
              @input="fetchAdditionalData" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          class="mt-2"
          label="Klucz unikalny postu (slug - pozostawienie pustego pola spowoduje automatyczne wygenerowanie sluga)">
          <validation-provider
            #default="{ errors }"
            name="Klucz unikalny postu (slug)"
            rules="alpha_dash|min:4|max:120">
            <b-form-input id="v-slug" placeholder="Klucz unikalny postu (slug)" v-model="form.slug" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mt-2" label="Aktywować post">
          <b-form-checkbox v-model="form.is_active">
            Aktywny post będzie wyświetlał się na stronie głównej
          </b-form-checkbox>
        </b-form-group>

        <b-form-group class="mt-2" label="Kategorie">
          <validation-provider #default="{ errors }" name="Kategorie">
            <v-select
              id="v-category"
              label="name"
              placeholder="Wybierz kategorie"
              v-model="form.categories"
              :clearable="false"
              :multiple="true"
              :deselectFromDropdown="false"
              :options="categories"
              :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mt-2" label="Opis SEO">
          <validation-provider #default="{ errors }" name="Opis SEO" rules="required|min:2|max:160">
            <b-form-textarea v-model="form.seo_description" :disabled="!lang" rows="3" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col>
        <h4 class="mb-2 mt-3">Treść postu</h4>

        <b-form-group class="mt-2" label="Tytuł">
          <validation-provider #default="{ errors }" name="Tytuł" rules="required|min:2|max:150">
            <b-form-input id="v-image-title" placeholder="Tytuł" v-model="form.title" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mt-0" label="Zdjęcie tytułowe">
          <b-button @click="toggleShowModal('title_image')" :disabled="!lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie tytułowe" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input v-if="files.title_image" type="hidden" name="Zdjęcie tytułowe" v-model="files.title_image.file" />
            <input v-else type="hidden" name="Zdjęcie tytułowe" v-model="files.title_image" />

            <ImageSelector
              v-if="showImagesModal.title_image"
              :files="filesArray"
              :show-modal="showImagesModal.title_image"
              @closeModal="toggleShowModal('title_image')"
              @submit="(fileList) => handleFile(fileList[0], 'title_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.title_image"
            :image="files.title_image"
            @deleteImage="() => handleDelete('title_image')" />
        </b-form-group>

        <b-form-group class="mt-2" label="Treść - część 1">
          <validation-provider #default="{ errors }" name="Treść - część 1" rules="required">
            <quill-editor v-model="form.description_1" :options="editorOption" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mt-2" label="Treść - część 2">
          <validation-provider #default="{ errors }" name="Treść - część 2" rules="required">
            <quill-editor v-model="form.description_2" :options="editorOption" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="mt-2" label="Treść - część 3">
          <quill-editor v-model="form.description_3" :options="editorOption" :disabled="!lang" />
        </b-form-group>
      </b-col>

      <b-col>
        <h4 class="mb-2 mt-3">Cytat</h4>

        <b-form-group class="mt-2" label="Cytat">
          <validation-provider #default="{ errors }" name="Cytat" rules="min:2|max:500">
            <b-form-input id="v-quote" ref="quote" placeholder="Cytat" v-model="form.quote" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group v-if="form.quote" class="mt-2" label="Autor cytatu">
          <validation-provider #default="{ errors }" name="Autor cytatu" rules="required|min:2|max:100">
            <b-form-input id="v-quote" placeholder="Autor cytatu" v-model="form.quote_author" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group v-if="form.quote" class="mt-2" label="Opis autora">
          <validation-provider #default="{ errors }" name="Opis autora" rules="required|min:2|max:150">
            <b-form-input id="v-quote" placeholder="Opis autora" v-model="form.quote_author_desc" :disabled="!lang" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group v-if="form.quote" label="Zdjęcie autora">
          <b-button @click="toggleShowModal('quote_author_image')" :disabled="!lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie autora" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input
              v-if="files.quote_author_image"
              type="hidden"
              name="Zdjęcie autora"
              v-model="files.quote_author_image.file" />
            <input v-else type="hidden" name="Zdjęcie autora" v-model="files.quote_author_image" />

            <ImageSelector
              v-if="showImagesModal.quote_author_image"
              :files="filesArray"
              :show-modal="showImagesModal.quote_author_image"
              @closeModal="toggleShowModal('quote_author_image')"
              @submit="(fileList) => handleFile(fileList[0], 'quote_author_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.quote_author_image"
            :image="files.quote_author_image"
            @deleteImage="() => handleDelete('quote_author_image')" />
        </b-form-group>
      </b-col>

      <!-- Form repeater -->
      <b-col>
        <b-form-group>
          <h4 class="mb-2 mt-3">Dodatkowe zdjęcia</h4>

          <b-button @click="toggleShowModal('site_images')" :disabled="!lang"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Zdjęcie">
            <ImageSelector
              v-if="showImagesModal.site_images"
              :files="files.site_images"
              :is-single="false"
              :show-modal="showImagesModal.site_images"
              @closeModal="toggleShowModal('site_images')"
              @submit="(value) => handleFiles(value, 'site_images')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <div v-for="(file, index) in files.site_images" :key="index">
            <ImagePreview
              v-if="file.file"
              :image="file"
              @deleteImage="() => handleDeleteFromMany('site_images', index)" />
          </div>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <h4 class="mb-2 mt-3">Załączniki do postu</h4>
          <v-select
            id="v-attachment-select"
            label="name"
            placeholder="Wybierz załączniki"
            v-model="form.attachments"
            :disabled="!lang"
            :multiple="true"
            :clearable="false"
            :deselectFromDropdown="false"
            :options="attachmentFiles" />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <h4 class="mb-2 mt-3">Linki do filmów w karuzeli</h4>
          <b-col v-for="(item, index) in form.videos" :id="item.id" :key="item.id" ref="row">
            <b-form-group>
              <b-form-input
                v-model="item.url"
                placeholder="Link do filmu"
                :disabled="!lang"
                :key="item.id"
                :name="`videos[${index}][url]`"
                rules="url" />
            </b-form-group>

            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mb-2"
              @click="form.videos.splice(index, 1)">
              <span>Usuń</span>
            </b-button>
          </b-col>
        </b-form-group>
      </b-col>

      <b-col>
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-primary"
          @click="addNextVideo"
          :disabled="!lang">
          <span>Dodaj film</span>
        </b-button>
      </b-col>

      <b-col class="mt-5">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          :disabled="!lang">
          Zapisz
        </b-button>
      </b-col>

      <b-col v-if="hasChildren" class="pl-0 mt-4">
        <h3 class="mb-2">Translacje</h3>
        <b-table responsive bordered striped hover :items="post.children" :fields="translations.fields">
          <template #cell(title)="row">
            <b-link :to="{ name: 'blog-posts-edit', params: { slug: row.item.slug } }" v-if="row.item.id">
              {{ row.item.title }}
            </b-link>
          </template>
        </b-table>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';

import FileUploadMixin from '@/mixins/FileUploadMixin';
import NotificationMixin from '@/mixins/NotificationMixin';
import { toolbarSettings } from '@/constants/quill-editor';
import { getLanguages } from '@/constants/languages';
import { fetchByLanguage } from '@/http/blog/categories';
import { allByLanguage as allFiles } from '@/http/downloadable-files';

import { getLanguageByLang } from '@/constants/languages';
import { LanguageFormatter } from '@/formatters/LanguageFormatter';

import ImagePreview from '@/components/shared/ImagePreview.vue';
import ImageSelector from '@/components/shared/ImageSelector.vue';

export default {
  mixins: [FileUploadMixin, NotificationMixin],
  props: {
    post: {
      type: Object,
      default: () => {},
    },
    posts: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    quillEditor,
    ImagePreview,
    ImageSelector,
  },

  data() {
    return {
      categories: [],
      lang: null,
      form: {
        title: '',
        title_image: null,
        title_image_preview: null,
        quote: null,
        quote_author: null,
        quote_author_desc: null,
        description_1: '',
        description_2: '',
        description_3: '',
        seo_description: '',
        slug: '',
        site_images: [],
        categories: [],
        attachments: [],
        videos: [],
      },
      attachmentFiles: [],
      languages: getLanguages(),
      editorOption: {
        modules: {
          toolbar: toolbarSettings,
        },
      },
      translations: {
        fields: [
          { key: 'title', label: 'Tytuł' },
          {
            key: 'lang',
            label: 'Język',
            formatter: (val) => {
              return LanguageFormatter(val);
            },
          },
        ],
      },
    };
  },

  created() {
    this.setFileUploadData(
      ['title_image', 'site_images', 'quote_author_image'],
      ['title_image', 'site_images', 'quote_author_image'],
    );
    this.files.site_images = [];
  },

  watch: {
    async post(newValue, oldValue) {
      this.lang = getLanguageByLang(newValue.lang);
      await this.setImage(this.post.title_image, 'title_image');
      await this.setImages(this.post.site_images, 'site_images');
      await this.setImage(this.post.quote_author_image, 'quote_author_image');

      this.form = newValue;

      console.log(newValue);

      if (newValue.lang !== oldValue.lang) {
        this.fetchAdditionalData();
      }
    },
  },

  computed: {
    availableLanguages() {
      if (this.form?.parent?.children) {
        const mappedChildrenLanguages = this.form.parent.children.map((child) => child.lang);
        mappedChildrenLanguages.push(this.form.parent.lang);

        return this.languages.filter((lang) => !mappedChildrenLanguages.includes(lang.lang));
      }

      return this.languages.filter((lang) => lang.lang !== this.form.lang);
    },
    availableParents() {
      return this.posts.filter((post) => post.id !== this.form.id && !post.parent);
    },
    hasChildren() {
      return this.form?.children?.length > 0;
    },
  },

  methods: {
    fetchAdditionalData() {
      return Promise.all([this.fetchCategories(), this.fetchFiles()]);
    },
    async fetchCategories() {
      try {
        const { data } = await fetchByLanguage(this.lang.lang, {});

        this.categories = data.data;
      } catch (error) {
        this.showErrorNotification(
          'Błąd przy pobieraniu kategorii',
          'Nie udało się pobrać kategorii. Skontaktuj się ze swoim developerem.',
        );
      }
    },
    async fetchFiles() {
      try {
        const { data } = await allFiles({
          lang: this.lang.lang,
        });

        this.attachmentFiles = data.data;
      } catch (error) {
        this.showErrorNotification(
          'Problem z pobraniem danych',
          'Wystąpił problem z pobraniem plików. Skontaktuj się ze swoim developerem.',
        );
      }
    },
    addNextVideo() {
      this.form.videos.push({
        id: Math.random(),
        url: '',
      });
    },
    onSubmit() {
      const data = {
        ...this.form,
        videos: this.form.videos.map((video) => video.url),
        title_image: this.files.title_image,
        site_images: this.files.site_images,
        quote_author_image: this.files.quote_author_image,
        lang: this.lang.lang,
      };

      this.$emit('onSubmit', data);
    },
    validationForm() {
      this.$refs.postObserver.validate().then((success) => {
        if (success) {
          return this.onSubmit();
        }

        this.showFormValidationNotification();
      });
    },
    resetLanguage() {
      this.lang = null;
      this.form.lang = null;
    },
    async cloneMedia() {
      try {
        await this.setImage(this.form.parent.title_image, 'title_image');
        await this.setImages(this.form.parent.site_images, 'site_images');
        if (this.form.parent.quote) {
          await this.setImage(this.form.parent.quote_author_image, 'quote_author_image');
        }
      } catch {
        this.showFormValidationNotification();
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
