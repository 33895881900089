import axios from '@/http/axios';

export const all = (params = {}) => {
  return axios.get('/categories', params);
};

export const fetchByLanguage = (language, params = {}) => {
  return axios.get(`/categories/lang/${language}`, params);
};

export const show = (id, params = {}) => {
  return axios.get(`/categories/${id}`, params);
};

export const create = (payload) => {
  return axios.post('/categories', payload);
};

export const update = (id, payload) => {
  return axios.put(`/categories/${id}`, payload);
};

export const destroy = (id) => {
  return axios.delete(`/categories/${id}`);
};
